import React from 'react'
import './Companies.css'
function Companies() {
  return (
   <section className='c-wrapper'>
<div className="paddings innerWidth flexCenter c-container">
    <img src="./prologis.png" alt="" />
    <img src="./tower.png" alt="" />
    <img src="./equinix.png" alt="" />
    <img src="./realty.png" alt="" />
</div>

   </section>
  )
}

export default Companies